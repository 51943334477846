import { Link } from "react-scroll"
import "./App.css"

function App() {
	return (
		<section className='space-y-10 bg-gray-50'>
			<div className='relative'>
				<div className='h-full w-full absolute inset-0 z-0'>
					<img className='h-full w-full ' src='/images/bg.a09273522aff11d7afd5.png' alt='bg' />
				</div>
				<div className='relative z-10 px-6 sm:px-16 pt-16 pb-24'>
					<div className=' sm:flex justify-between items-center gap-10'>
						<div className='text-white w-full space-y-2'>
							<h1 className='text-2xl sm:text-5xl font-bold'>
								Spend <span className='text-yellow-400'>Crypto</span>
							</h1>
							<h1 className='text-xl sm:text-4xl font-bold'>the fast and easy way while</h1>
							<h1 className='text-3xl sm:text-6xl font-bold'>Earning Points</h1>
							<p className='pt-8 text-xs sm:text-base'>Make mobile top-ups, buy gift cards and pay bills with crypto for more than 5,000 products in over 170 countries and earn points.</p>
							<Link
								className='flex justify-start items-center gap-x-2 mt-4 font-bold cursor-pointer capitalize bg-white px-4 py-1 w-max text-gray-500 rounded-full'
								activeClass='active'
								to='waitlist'
								spy={true}
								smooth={true}
								offset={-70} // Adjust the offset according to your layout
								duration={500}
							>
								Join our waitlist
								<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
									<path strokeLinecap='round' strokeLinejoin='round' d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3' />
								</svg>
							</Link>
						</div>
						<div className='w-full relative'>
							<div className='absolute inset-0 z-0'>
								<img className='h-full w-full ' src='/images/shape.0634d3115491b80c23fe.png' alt='bg-card' />
							</div>
							<div className='relative z-10 p-10'>
								<img className='h-full w-full ' src='/images/giftcards-1024x767 1.66bb8bc6f36228c4d64b.png' alt='hero' />
							</div>
						</div>
					</div>
					<div className='flex justify-center items-center pt-8 sm:pt-0'>
						<img className='h-8 sm:h-14  w-8 sm:w-14 object-contain' src='/images/2.png' alt='mouse' />
					</div>
				</div>
			</div>

			<div className='relative'>
				{/* <div className='h-full w-full absolute inset-0 z-0'>
					<img className='h-full w-full opacity-40 object-contain' src='/images/world.svg' alt='world map' />
				</div> */}
				<div className='p-6 sm:p-16  text-center space-y-6  relative z-10'>
					{/* <h1 className='text-2xl sm:text-4xl font-bold'>Why Switch Hive?</h1> */}
					<h2 className='text-base sm:text-2xl font-semibold'>Switchive will empower the crypto community to effortlessly spend their crypto coins directly on their daily necessities.</h2>

					<div className='w-full sm:h-56 space-y-3 sm:space-y-0 sm:flex justify-between items-center gap-x-6'>
						<div className='w-full h-full bg-white p-4 sm:p-10 rounded-xl shadow-md text-sm sm:text-base border border-slate-300 font-semibold text-gray-700'>It is time to use crypto as normal currency. Start paying your bills, utilities, mobile top ups, gift cards and everyday purchases using crypto.</div>
						<div className='w-full h-full bg-white p-4 sm:p-10 rounded-xl shadow-md text-sm sm:text-base border border-slate-300 font-semibold text-gray-700'>With every purchase and referral, you will earn points that can be redeemed and used for future purchases on the platform.</div>
						<div className='w-full h-full bg-white p-4 sm:p-10 rounded-xl shadow-md text-sm sm:text-base border border-slate-300 font-semibold text-gray-700'>Say goodbye to the traditional approach to bill payments and hello to innovative web3 smart payment solutions.</div>
						<div className='w-full h-full bg-white p-4 sm:p-10 rounded-xl shadow-md text-sm sm:text-base border border-slate-300 font-semibold text-gray-700'>Join us in embracing the future of finance, as we unlock the future of bill payments.</div>
					</div>
				</div>
				<div className='p-6 sm:p-16  text-center space-y-6 relative z-10 '>
					<h1 className='text-2xl sm:text-4xl font-bold'>Blockchains We Support</h1>
					{/* <h2 className='text-base sm:text-2xl font-semibold'>We provide a wide range of courses covering the fundamentals of Web3, blockchain technology, Smart Contracts , decentralized applications and other related topics.</h2> */}

					<div className='w-full h-auto grid grid-cols-6 justify-center items-center gap-4 pt-4 sm:pt-8'>
						{/* <div className='w-full h-full flex justify-start items-center gap-x-2 bg-white p-2 rounded shadow-md text-sm font-bold text-gray-700'>
							<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={2} stroke='currentColor' className='w-5 h-5 text-teal-500'>
								<path strokeLinecap='round' strokeLinejoin='round' d='M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5' />
							</svg>
							Blockchain Development
						</div>
						 */}
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/1.svg' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/2.webp' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/3.png' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/4.jpg' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/5.jpg' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/6.png' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/7.png' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/8.svg' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/9.webp' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/10.png' alt='icon' />
						<img className='h-16 w-16  mx-auto object-contain' src='/images/blockchain/11.jpg' alt='icon' />
					</div>
					<p className='text-base sm:text-2xl font-semibold'>Make your everyday purchases using thousands of different tokens across over 10 blockchains.</p>
				</div>
			</div>

			<div id='waitlist' className='p-6 sm:p-16  text-center space-y-6'>
				<h1 className='text-2xl sm:text-4xl font-bold'>Join Our Waitlist</h1>
				<h2 className='text-base sm:text-2xl font-semibold'>Register below for early access. We will notify you when the platform is launched. (early users stand the chance to qualify for discounts and other promotional offers)</h2>
			</div>
			<div className='h-auto w-full'>
				<iframe aria-label='Contact Us' frameborder='0' width='100%' height='440px' src='https://forms.zohopublic.com/switchive/form/ContactUs/formperma/4LxjJW-cR-487GGV5yiWVp9llIwujZDzOJ0rEZLOJ8k'></iframe>
			</div>

			<div className='px-6 sm:px-16 space-y-5 sm:space-y-10 py-4'>
				<h1 className='text-center text-xl sm:text-4xl font-bold'>Join the Switchive Community</h1>

				<div className='flex justify-center items-center flex-wrap gap-6 '>
					{/* <a href='#' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-16 w-6 sm:w-16 object-contain' src='/images/1.svg' alt='discord' />
						<p className='text-xs sm:text-base'>Discord</p>
					</a> */}
					<a href='https://www.facebook.com/Switchive/?_rdc=1&_rdr' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/5.svg' alt='Facebook' />
						<p className='text-xs sm:text-base'>Facebook</p>
					</a>
					<a href='https://www.linkedin.com/company/switchive/' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/6.svg' alt='Linkedin' />
						<p className='text-xs sm:text-base'>Linkedin</p>
					</a>
					<a href='https://www.tiktok.com/@switchive?_t=8hxq8Q2cFnx&_r=1' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/7.svg' alt='TikTok' />
						<p className='text-xs sm:text-base'>TikTok</p>
					</a>
					<a href='https://www.pinterest.com/switchive/?invite_code=1357444bd1ec4d80b7872ae0c4c90025&sender=938648884744471956' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/8.svg' alt='Pinterest' />
						<p className='text-xs sm:text-base'>Pinterest</p>
					</a>
					<a href='https://t.me/switchiveofficial' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/2.svg' alt='Telegram' />
						<p className='text-xs sm:text-base'>Telegram</p>
					</a>
					<a href='https://www.instagram.com/switchive/' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/3.svg' alt='Instagram' />
						<p className='text-xs sm:text-base'>Instagram</p>
					</a>
					<a href='https://twitter.com/switchive' className=' flex flex-col justify-center items-center'>
						<img className='h-6 sm:h-10 w-6 sm:w-10 object-contain' src='/images/4.svg' alt='x' />
						<p className='text-xs sm:text-base'>X</p>
					</a>
				</div>

				<p className='text-sm text-center font-light pt-12'>Switchive © 2023 – All Rights Reserved</p>
			</div>
		</section>
	)
}

export default App
